import React, { Component, useState, useEffect } from 'react';
// import { w3cwebsocket as W3CWebSocket } from "websocket";
import './App.css';

import { Wheel } from 'react-custom-roulette';
import { setInterval } from 'timers';

// const client = new W3CWebSocket('ws://139.99.237.126:3005')



// const data = [
//   { option: 'NIKKI' },
//   { option: 'EARBOY' },
//   { option: 'NUGGET', style: { textColor: '#f9dd50' } },
//   { option: 'HENPO' },
//   { option: 'ARC' },
//   { option: 'NUTTY' },
//   { option: 'ROULETTE', style: { textColor: '#70bbe0' } },
//   { option: 'WHEEL' },
// ];

const backgroundColors = ['#ff8f43', '#70bbe0', '#0b3351', '#f9dd50'];
const textColors = ['#0b3351'];
const outerBorderColor = '#eeeeee';
const outerBorderWidth = 10;
const innerBorderColor = '#30261a';
const innerBorderWidth = 0;
const innerRadius = 0;
const radiusLineColor = '#eeeeee';
const radiusLineWidth = 8;
const fontSize = 17;
const textDistance = 60;

const App = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [data, setData] = useState([]);


  

  useEffect(() => {

    // const client = new WebSocket('ws://139.99.237.126:3005')
    
    function heartbeat(ws:WebSocket) {
      let message = {
          type: 'PING'
      };
      console.log('SENT: ' + JSON.stringify(message) + '\n');
      ws.send(JSON.stringify(message));
    }

    function connect() {

      var ws = new WebSocket('wss://139.99.237.126:3005');
      
      var heartbeatInterval = 1000 * 60; //ms between PING's
      var reconnectInterval = 1000 * 3; //ms to wait before reconnect
      var heartbeatHandle:NodeJS.Timer;
  
      
  
      ws.onopen = function(event) {
        
        console.log('INFO: Socket Opened\n');
        heartbeat(ws);
        heartbeatHandle = setInterval(() =>heartbeat(ws), heartbeatInterval);
        
      };
  
      ws.onerror = function(error) {
        console.log('ERR:  ' + JSON.stringify(error) + '\n');
      };
  
      ws.onmessage = function(event) {
          const message = JSON.parse(event.data);
          console.log('RECV: ' + JSON.stringify(message) + '\n');
          if (message.type === 'RECONNECT') {
              console.log('INFO: Reconnecting...\n');
              setTimeout(connect, reconnectInterval);
          }
          if (message.type === 'MESSAGE') {
            if (message.data.type === "wheelspin") {

              setData(message.data.data)
      
              handleSpinClick(message.data.newPrizeNumber)
              
            }

            
          }
      };
  
      ws.onclose = function() {
          console.log('INFO: Socket Closed\n');
          clearInterval(heartbeatHandle);
          console.log('INFO: Reconnecting...\n');
          setTimeout(connect, reconnectInterval);
      };
  
  }

  connect()






    // client.onopen = () => {
    //   console.log('Websocket connected')
    // }
    // client.onmessage = (message) =>{
    //   const datafromserver = JSON.parse(message.data)
    //   console.log(datafromserver)


    //   if (datafromserver.event === "wheelspin") {

    //     setData(datafromserver.data.data)

    //     handleSpinClick(datafromserver.newPrizeNumber)
        
    //   }
    // }
  },[]);

  const handleSpinClick = (newPrizeNumber:number) => {
    // const newPrizeNumber = Math.floor(Math.random() * data.length)
    setPrizeNumber(newPrizeNumber)
    setMustSpin(true)
    setHidden(false)
  }

  // const sendtoserver = (value:string) => {
  //   client.send(JSON.stringify({
  //     type: "message",
  //     msg: value
  //   }) )
    
  // }


  

  return (
    <div className="App">
      <div className={hidden ? 'basket hide' : 'basket show'}
      >
        <div>
          <Wheel


            mustStartSpinning={mustSpin}
            prizeNumber={prizeNumber}
            data={data}
            backgroundColors={backgroundColors}
            textColors={textColors}
            fontSize={fontSize}
            outerBorderColor={outerBorderColor}
            outerBorderWidth={outerBorderWidth}
            innerRadius={innerRadius}
            innerBorderColor={innerBorderColor}
            innerBorderWidth={innerBorderWidth}
            radiusLineColor={radiusLineColor}
            radiusLineWidth={radiusLineWidth}
            // perpendicularText
            textDistance={textDistance}

            onStopSpinning={() => {
              setMustSpin(false)
              // sendtoserver(data[prizeNumber].option)
              console.log(prizeNumber)
              setTimeout(() => {
                setHidden(true)
                
              }, 4000);
            }}
          />
          
        </div>
        
      </div>
      {/* <button className={'spin-button'} onClick={handleSpinClick}>
            SPIN
          </button> */}
    </div>
  );
};

export default App;
